import React, { Component } from 'react';
import { Navbar, Nav, NavItem, Panel, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Well } from 'react-bootstrap';
import MathJax from 'react-mathjax';
import * as math from 'mathjs';
import Plot from 'react-plotly.js';
import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollUpButton from 'react-scroll-up-button';
import './App.css';

import wechat_shoukuan from './fanzhh_wechat_shoukuan.jpg';
import zhifubao_shoukuan from './fanzhh_zhifubao_shoukuan.png';
import idanci from './idanci.png';

class App extends Component {
  
  constructor(props) {
	  super(props);
	  this.state = {
			funcs: [
				{
					"id": 1,
					"name": "yiyuanyici",
					"title": "一次函数",
					"params": {"a":0, "b":0},
					"range": {"min":-20, "max":20, "step":0.2},
					"caption": "y=ax+b",
					"expr": "ax+b",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": ""
				},
				{
					"id": 2,
					"name": "yiyuanerci",
					"title": "二次函数",
					"params": {"a":0, "b":0, "c":0},
					"range": {"min":-20, "max":20, "step":0.2},
					"caption": "y=ax^2+bx+c",
					"expr": "ax^2+bx+c",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": ""
				},
				{
					"id": 3,
					"name": "zhishu",
					"title": "指数函数",
					"params": {"a":0},
					"range": {"min":-20, "max":20, "step":0.2},
					"caption": "y=a^x  (a>0且a\\neq1)",
					"expr": "a^x",
					"exp": "",
					"expr_reverse": "(1/a)^x",
					"exp_reverse": "",
					"reverse_title": "指数函数(1/a)"
				},
				{
					"id": 4,
					"name": "duishu",
					"title": "对数函数",
					"params": {"a":0},
					"range": {"min":-20, "max":20, "step":0.2},
					"caption": "y=\\log_{a}x  (a>0且a\\neq1)",
					"expr": "log(x,a)",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": ""
				},
				{
					"id": 5,
					"name": "mi",
					"title": "幂函数",
					"params": {"a":0},
					"range": {"min":-20, "max":20, "step":0.2},
					"caption": "y=x^a",
					"expr": "x^a",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": ""
				},
				{
					"id": 6,
					"name": "defined",
					"title": "自定义函数",
					"params": {},
					"range": {"min":-20,"max":20,"step":0.2},
					"caption": "",
					"expr": "",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": ""
				},
				{
					"id": 7,
					"name": "dichotomy",
					"title": "二分法求零点",
					"params": {},
					"range": {"min":0,"max":1,"step":0.1},
					"caption": "",
					"expr": "",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": "",
				},
				{
					"id": 8,
					"name": "coming",
					"title": "未完待续 ",
					"params": {},
					"range": {},
					"caption": "",
					"expr": "",
					"exp": "",
					"expr_reverse": "",
					"exp_reverse": "",
				}
			],
		}
		this.rangeChange = this.rangeChange.bind(this);
		this.txtChange = this.txtChange.bind(this);
		this.rangeTxtChange = this.rangeTxtChange.bind(this);
		this.updateFunc = this.updateFunc.bind(this);
		this.productParams = this.productParams.bind(this);
		this.plot = this.plot.bind(this);
		this.plotDefined = this.plotDefined.bind(this);
		this.plotDichotomy = this.plotDichotomy.bind(this);
  }

/* 范围或文本框内容改变后，更新相应函数参数的状态 */
updateFunc(id, value) {
	let func_name = id.split('_')[0];
	let func_param = id.split('_')[2];
	let funcs = this.state.funcs;
	let expr = funcs.find(func=>func.name===func_name).expr;
	let expr_reverse = funcs.find(func=>func.name===func_name).expr_reverse;
	let exp = "";
	let exp_reverse = "";
	let tmpstr1 = expr;
	let tmpstr2 = expr_reverse;
	let params = funcs.find(func=>func.name===func_name).params;
	params[func_param] = value;
	Object.keys(params).forEach((key)=>{
		if (expr.includes(key)) {
			if (params[key]<0 && tmpstr1.includes('+'+key)) {
				tmpstr1 = tmpstr1.replace('+'+key,params[key]);
			} else {
				tmpstr1 = tmpstr1.replace(key,params[key]);
			}
		}
		/* 逆函数，或者第二个函数 */
		if (expr_reverse !== "") {
			if (expr_reverse.includes(key)) {
				if (params[key]<0 && tmpstr1.includes('+'+key)) {
					tmpstr2 = tmpstr2.replace('+'+key,params[key]);
				} else {
					tmpstr2 = tmpstr2.replace(key,params[key]);
				}
			}
		}
	});
	exp = tmpstr1;
	exp_reverse = tmpstr2;
	funcs.find(func=>func.name===func_name).exp = exp;
	funcs.find(func=>func.name===func_name).exp_reverse = exp_reverse;
	funcs.find(func=>func.name===func_name).params[func_param] = value;
	this.setState({funcs});
}

/* 范围改变事件 */
rangeChange(event) {
	let id = event.target.id;
	let value = event.target.value;
	let func_name = id.split('_')[0];
	let func_param = id.split('_')[2];
	if (value % 1 !== 0) {
		value = parseFloat(value).toFixed(1);
	} 
	this.updateFunc(id, value);
	document.getElementById(func_name+"_param_"+func_param+"_txt").value = value;
}

/* x轴调整文本框改变事件 */
rangeTxtChange(event) {
	let id = event.target.id;
	let func_name = id.split('_')[0];
	let range_param = id.split('_')[2];
	let value = event.target.value;
	if (value.trim() === "") {
		return;
	}
	if (isNaN(Number(value))) {
		return;
	} else {
		value = Number(value);
	}
	let funcs = this.state.funcs;
	funcs.find(func=>func.name===func_name).range[range_param] = value;
	this.setState(funcs);
}

/* 文本框改变事件 */
txtChange(event) {
	let id = event.target.id;
	let value = event.target.value;
	if (isNaN(Number(value))) {
		return;
	} else {
		value = Number(value);
	}
	if (value %1 !== 0) {
		value = parseFloat(value).toFixed(1);
	} else {
		value = parseInt(value);
	} 
	this.updateFunc(id, value);
}

/* 生成范围和文本框控件 */
productParams(func) {
	let results = [];
	if (func.name !== "defined" && func.name !=="dichotomy") {
		/* 不是自定义函数 */
		Object.keys(func.params).forEach((key)=>{
			results.push(
				<Row key={func.name + '_param_' + key}>
				<Col xs={1} sm={1} md={1}>{key}</Col>
				<Col xs={9} sm={9} md={10}>
				<input 
					type="range" 
					id={func.name + '_param_' + key} 
					min={func.range.min} 
					max={func.range.max} 
					step={func.range.step} 
					onChange={this.rangeChange} 
				/>
				</Col>
				<Col xs={1} sm={1} md={1}>
					<input 
						type="text"
						id={func.name + '_param_' + key + '_txt'}
						size="1"
						onChange={this.txtChange}
						defaultValue="0"
					/>
				</Col>
				</Row>
			)
		});
	} else {
		if (func.name === "defined" || func.name === "dichotomy") {
			/* 自定义函数 */
			results.push(
				<div key={func.name+"_0"}>
				<Row key={func.name+"_2"}>
					<Col xs={10} sm={10} md={10}>
						<Form inline>
						<FormGroup>
							<ControlLabel>
							{func.name==="dichotomy"?"请在x上下限中分别输入区间，在步长中填入精确度，":""}
							请输入函数表达式: &nbsp;&nbsp;
							</ControlLabel>
							<FormControl
								id={func.name+"_expr_txt"}
								type="text"
								placeholder="常用函数的表达式：a的n次方为a^n;以a为底n的对数为log(n,a)。"
								size="60"
							/>
						</FormGroup>
						</Form>
					</Col>
					<Col xs={2} sm={2} md={2}>
						{func.name==="defined"?(
							<Button onClick={this.plotDefined}>
								绘图
							</Button>
						):(
							<Button onClick={this.plotDichotomy}>
								求零点
							</Button>
						)}
					</Col>
					</Row>
				</div>
			)
		} 		
	}

  /* 输出x轴上下限及步长 */ 
  if (func.name !== 'coming') {
	results.push(
		<Row key={func.name+"_4"} style={{ margin:'10px 1px 1px 0px'}}>
			<Form inline>
				<Col xs={4} sm={4} md={4}>
				<FormGroup>
					<ControlLabel>
						x下限
					</ControlLabel>
					<FormControl
						id={func.name+"_range_min"}
						type="text"
						size="6"
						onChange={this.rangeTxtChange}
						defaultValue={func.range.min}
					/>
				</FormGroup>
				</Col>
				<Col xs={4} sm={4} md={4}>
				<FormGroup>
					<ControlLabel>
						x上限
					</ControlLabel>
					<FormControl
						id={func.name+"_range_max"}
						type="text"
						size="6"
						defaultValue={func.range.max}
						onChange={this.rangeTxtChange}
					/>
				</FormGroup>
				</Col>
				<Col xs={4} sm={4} md={4}>
				<FormGroup>
					<ControlLabel>
						x步长
					</ControlLabel>
					<FormControl
						id={func.name+"_range_step"}
						type="text"
						defaultValue={func.range.step}
						size="6"
						onChange={this.rangeTxtChange}
					/>
				</FormGroup>
				</Col>
			</Form>
		</Row>
	);}
		
	return results;
}

/* 自定义函数，根据输入公式更新状态中的公式 */
plotDefined() {
	let value = document.getElementById('defined_expr_txt').value;
	value = value.trim();
	if (value === "") {
		return;
	}
	value = value.split("=")
	if (value.length > 1) {
		value = value[1]
	} else {
		value = value[0]
	}
	let funcs = this.state.funcs;
	funcs.find(func=>func.name==='defined').expr = value;
	funcs.find(func=>func.name==='defined').exp = value;
	this.setState({funcs});
}

/* 二分法绘图 */
plotDichotomy() {
	let value = document.getElementById('dichotomy_expr_txt').value;
	value = value.trim();
	if (value === "") {
		return ;
	}
	let funcs = this.state.funcs;
	funcs.find(func=>func.name==='dichotomy').expr = value;
	funcs.find(func=>func.name==='dichotomy').exp = value;
	let expr = math.compile(value); 
	let min = funcs.find(func=>func.name==='dichotomy').range.min;
	let max = funcs.find(func=>func.name==='dichotomy').range.max;
	let step = funcs.find(func=>func.name==='dichotomy').range.step;
	let output = document.getElementById('dichotomy_result');
	output.value = value + '\n';
	let middle = min + (max-min)/2;
	let fx_min = expr.eval({x:min});
	let fx_max = expr.eval({x:max});
	let fx_middle = expr.eval({x:middle});
	/*output.value += '\n' + fx_min + fx_max + fx_middle + '\n';*/

	let i = 1;
	let fail = false;
	while (fx_min !==0 && fx_max !== 0 && fx_middle !== 0 && (math.abs(max-min) > step)) {
		if (i>=30) {
			output.value = output.value + '\n已超出最大循环次数，给定区间内未找到零点！';
			break;
		}
		output.value = output.value + '\n第' + i + '次循环：\n区间（' + min + '，'+ max + '），中点' +  middle;
		output.value = output.value + '\nf(' + min + ')=' + fx_min + '\nf(' + max + ')=' + fx_max + '\nf(' + middle + ')=' + fx_middle + '\n';
		if (fx_min * fx_middle < 0) {
			max = middle;
		} else if (fx_max * fx_middle < 0) {
			min = middle;			
		} else {
			output.value = output.value + '\n 给定区间内无零点！'
			fail = true;
			break;
		}
		middle = min + (max - min ) / 2;
		fx_min = expr.eval({x:min});
		fx_max = expr.eval({x:max});
	    fx_middle = expr.eval({x:middle});
		funcs.find(func=>func.name==='dichotomy').range.min = min;
		funcs.find(func=>func.name==='dichotomy').range.max = max;
		/*setTimeout(function(){ 
			this.setState({funcs});
		}.bind(this),2000);*/
		i ++;
	}

	if (i === 30) {
		return;
	}

	if (fail) {
		return;
	}

	if (fx_min === 0) {
		output.value = output.value + '\n' + min + ' is zero point.'
	} else if (fx_max === 0) {
		output.value = output.value + '\n' + max + ' is zero point.'
	} else if (fx_middle === 0) {
		output.value = output.value + '\n' + middle + ' is zero point.'
	} else {
		output.value = output.value + '\n' + min + ' or ' + max + ' 为零点.'
	}
}

/* 绘图 */
plot(min,max,step,expre,title,expr_reverse) {
	if (expre === "" || expre.includes('a') || expre.includes('b') || expre.includes('c')) {
		return "";
	} else {
			let data = [];
			try {
				const expr = math.compile(expre);
				const xValues = math.range(min,max,step).toArray();
				const yValues = xValues.map(function(x){
					return expr.eval({x:x})
				});
				const trace1 = {
					x: xValues,
					y: yValues,
					type: 'scatter',
					name: expre,
					hoverinfo: expre,
					showlegend: true,
				}
				data = [trace1];
				if (expr_reverse !== "") {
					const exp_reverse = math.compile(expr_reverse);
					const yValues2 = xValues.map(function(x){
						return exp_reverse.eval({x:x})
					});
					const trace2 = {
						x: xValues,
						y: yValues2,
						type: 'scatter',
						name: expr_reverse,
						hoverinfo: expr_reverse,
						showlegend: true
					}
					data.push(trace2);
				}
			}
			catch(err) {
				console.error(err);
				alert(err);
			}
			return( 
				<Plot 
					data={data} 
					layout={{  
						title: { title },
						autosize: true
					}}
					useResizeHandler={true}
					style={{width:"100%", height:"100%"}}
				/>)
	}
}
	
render() {
	let funcs = this.state.funcs;
	let panels = [];
	funcs.forEach((func)=>{
		panels.push(
				<ScrollableAnchor key={func.name} id={func.name}>
				<Panel key={func.id}>
					<Panel.Heading>{func.title}
						&nbsp;&nbsp;
						<MathJax.Provider>
							<MathJax.Node inline formula={func.caption} />
						</MathJax.Provider>
						</Panel.Heading>
						<Panel.Body>
							{
								func.name==="dichotomy"?
								(
									<Row>
										{/*<Col xs={5} sm={5} md={5}>*/}
											<textarea id={func.name+"_result"} rows="6" />
										{/*</Col>*/}
										{/*<Col xs={7} sm={7} md={7}>
											{ this.plot(func.range.min,func.range.max,func.range.step,func.exp,func.exp,func.exp_reverse) }
										</Col>*/}
									</Row>
								)
								:
								(this.plot(func.range.min,func.range.max,func.range.step,func.exp,func.exp,func.exp_reverse))
							}
						</Panel.Body>
						<Panel.Footer>
							{ this.productParams(func) }
						</Panel.Footer>
				</Panel>
				</ScrollableAnchor>
		)
	})
	let navs = [];
	funcs.forEach((func)=>{
		navs.push(
			<NavItem key={func.name+"_nav"} href={"#"+func.name}>{func.title}</NavItem>
		)
	})
    return (
        <div className="App">
        <Navbar collapseOnSelect>
	    	<Navbar.Header>
	    		<Navbar.Brand>
	    		  <a href="#home">在信息技术中学习高一数学</a>
	    		</Navbar.Brand>
	    		<Navbar.Toggle />
	    	</Navbar.Header>
	        <Nav>
				{/*
	    		<NavItem eventKey={1} href="#">
	    			Link
	    		</NavItem>
				*/}
	      </Nav>
		  {/*
	      <Nav pullRight>
	    		<NavDropdown eventKey={2} title="Dropdown" id="basic-nav-dropdown">
	    			<MenuItem eventKey={2.1}>Action</MenuItem>
	    			<MenuItem eventKey={2.2}>Another Action</MenuItem>
	    			<MenuItem divider />
	    			<MenuItem eventKey={2.3}>Separated link</MenuItem>
	    		</NavDropdown>
	      </Nav>
		  */}
	    </Navbar>
	    <div className="container">
	    	<Row>
		  		{/* 左侧导航栏 */}
	    		<Col xs={12} sm={3} md={3}>
					<Panel>
						<Panel.Heading>
							导航
						</Panel.Heading>
						<Panel.Body>
							<Nav bsStyle="pills" stacked>
								{ navs }
							</Nav>
						</Panel.Body>
					</Panel>
	    {/*<Panel>
						<Panel.Heading>
							本站内容如对您有帮助，欢迎打赏鼓励作者
						</Panel.Heading>
						<Panel.Body>
							<Row>
								<Col xs={6} sm={6} md={6}>
									微信
									<img src={wechat_shoukuan} alt="我的微信收款二维码" width="90" />
								</Col>
								<Col xs={6} sm={6} md={6}>
									支付宝
									<img src={zhifubao_shoukuan} alt="我的支付宝收款二维码" width="90" />
								</Col>
							</Row>
						</Panel.Body>
					</Panel>*/}
					<Panel>
						<Panel.Heading>
							友情链接
						</Panel.Heading>
						<Panel.Body>
							{/*<Nav bsStyle="pills" stacked>*/}
								{/*<NavItem href="https://www.idanci.top" target="_blank" rel="noopener noreferrer">鲁教版初中英语单词在线测试</NavItem>*/}
	    {/*<NavItem href="https://www.icuoti.top" target="_blank" rel="noopener noreferrer">基于知识点管理的在线错题本</NavItem>*/}
								{/*<NavItem href="https://www.jianshu.com/u/a1094257c6d0">我的简书专栏</NavItem>*/}
							{/*</Nav>*/}
							<div className="card">
								<a href="//idanci.devlabs.cc" target="_blank">
									<img src={idanci} alt="i单词" width="100%" />
								</a>
								<div className="card-body">
									<h4 className="card-title">
										鲁教五四学制初中英语测试
									</h4>
									<p className="card-text">
										鲁教2013版五四学制初中英语单词课文同步测试练习，包含7册62个单元共2435个单词和短语，其中1750个重点单词。还可在线收听课本和单词音频，不规则动词专项练习。
									</p>
									<a href="https://www.idanci.top" target="_blank" className="btn btn-primary">访问</a>
								</div>
							</div>
						</Panel.Body>
					</Panel>
	    		</Col>
	    		{/* 右侧内容栏 */}
				<Col xs={12} sm={9} md={9}>
					  {panels}
	    		</Col>
	    	</Row>
	    </div>
		{/* 底栏 */}
		<Well className="container">
		by <a href="mailto:fanzhh@gmail.com">
						fanzhh@gmail.com
					</a> 
					&nbsp;&nbsp;QQ&WeChat：403767822&nbsp;&nbsp; 
          QQ群：912893950&nbsp;&nbsp;欢迎提出问题和建议&nbsp;&nbsp;
	{/*<a href="http://www.miitbeian.gov.cn" target="_blank" rel="noopener noreferrer">鲁ICP备18020328号-3</a>*/}
		</Well>
		<div><ScrollUpButton /></div>
      </div>
    );
  }
}

export default App;
